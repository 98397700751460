import {
  Box,
  Button,
  Center,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useToast
} from '@chakra-ui/react'
import format from 'date-fns/format'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiPrinter } from 'react-icons/fi'
import fileSaver from 'file-saver'
import { useModal } from '../../hooks/useModal'
import { apiAuth } from '../../services/apiAuth'
import { Input } from '../Form/Input'
import { Select } from '../Form/Select'
import { SelectComponent } from '../Form/SelectComponent'
import { Switch } from '../Form/Switch'
import { ModalArrivedTime } from '../Modal/confirmArrivedTime'
import { ModalDocument } from '../Modal/modalDocument'

type TypeDocument = 'simple' | 'special'

type Document = string | undefined

type Template = {
  id: number
  name: string
}

type SelectTemplate = {
  value: number
  label: string
}

type Templates = Array<Template>

interface PrintButtonProps {
  id: number | string
  typePrint: 'form' |'prescription' | 'exam' | 'indication' | 'quotes'
  templates: Templates
  patientId: string
  isIconButton?: boolean
  nameDocument?: string
  code?: string | number
  isHaveAttendance?: boolean
  recordId?: number
}

export const PrintButton: React.FC<PrintButtonProps> = ({
  id,
  typePrint,
  templates,
  patientId,
  isIconButton,
  nameDocument,
  code,
  isHaveAttendance,
  recordId
}) => {
  // translate
  const [t] = useTranslation('printButton')
  const { defaultTemplate, setDefaultTemplate } = useModal()
  const toast = useToast()
  const optionsDocument = [
    { value: 'simple', label: `${t('simple')}` },
    { value: 'special', label: `${t('special')}` }
  ]
  const optionsPaper = [
    { value: 'A4', label: `${t('A4')}` },
    { value: 'A5', label: `${t('A5')}` }
  ]
  // state configOptions
  const [isOpen, setIsOpen] = useState(false)
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'))
  const [isPrintDate, setIsPrintDate] = useState(true)
  const [isPrintDiscount, setIsPrintDiscount] = useState(false)
  const [selectTemplate, setSelectTemplate] = useState<SelectTemplate | null>(
    defaultTemplate
  )
  const [typeDocument, setTypeDocumento] = useState<TypeDocument | string>(
    'simple'
  )
  const [paperType, setPaperType] = useState('A4')
  // document states
  const [document, setDocument] = useState<Document>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenDocument, setIsOpenDocument] = useState(false)

  const handleConfirmationPrint = async () => {
    // validation

    if (!selectTemplate) {
      toast({
        status: 'error',
        position: 'top-right',
        description: 'Selecione um template para geração do documento',
        title: 'Atenção!'
      })

      return
    }

    setIsOpen(false)
    setIsLoading(true)

    if (typePrint !== 'quotes') {
      try {
        const { data: documentData } = await apiAuth.get(
          `clinics/patients/${patientId}/generate-prescription-pdf/`,
          {
            params: {
              id,
              template_id: selectTemplate?.value,
              type: typePrint === 'indication' ? 'simple' : typeDocument,
              type_print: typePrint,
              paper_type: paperType,
              is_print_date: isPrintDate === true ? '1' : '0',
              now: isPrintDate ? date : undefined,
              is_attendance: isHaveAttendance === true ? '0' : '1',
              record_id: recordId || undefined
            },
            responseType: 'blob'
          }
        )

        const reader = new FileReader()
        reader.readAsDataURL(documentData)
        reader.onloadend = (event) => {
          const base64data: any = event.target?.result
          setDocument(base64data)
        }
      } catch (error: any) {
        toast({
          status: 'error',
          position: 'top-right',
          description: 'Não foi possível gerar documento',
          title: 'Atenção!'
        })

        setIsLoading(false)

        return
      }
    }

    if (typePrint === 'quotes') {
      try {
        const { data: documentData } = await apiAuth.get(
          `clinics/quotes/${id}/pdf/`,
          {
            params: {
              template_id: selectTemplate?.value,
              paper_type: paperType,
              is_print_discount: isPrintDiscount === true ? '1' : '0'
            },
            responseType: 'blob'
          }
        )

        // pegar nome do documento
        fileSaver(
          documentData,
          `${nameDocument || ''}-${format(new Date(), 'dd/MM/yyyy')} ${
            code || ''
          }` || 'download'
        )

        const reader = new FileReader()
        reader.readAsDataURL(documentData)
        reader.onloadend = (event) => {
          const base64data: any = event.target?.result
          setDocument(base64data)
        }
      } catch (error: any) {
        toast({
          status: 'error',
          position: 'top-right',
          description: 'Não foi possível gerar documento',
          title: 'Atenção!'
        })

        setIsLoading(false)

        return
      }
    }

    setIsLoading(false)
    setIsOpenDocument(true)
  }

  useEffect(() => {
    setSelectTemplate(defaultTemplate)
  }, [defaultTemplate])

  return (
    <>
      {!isIconButton ? (
        <Button
          size="sm"
          bg="blue.300"
          color="white"
          onClick={() => setIsOpen(true)}
        >
          <FiPrinter />
        </Button>
      ) : (
        <Tooltip label="Imprimir" aria-label="Imprimir">
          <Box>
            <FiPrinter
              color="#9699B0"
              size={18}
              style={{ cursor: 'pointer' }}
              onClick={() => setIsOpen(true)}
            />
          </Box>
        </Tooltip>
      )}
      <ModalArrivedTime
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={t('title')}
        key={id}
        onUpdate={async () => {
          await handleConfirmationPrint()
        }}
      >
        <Stack w="100%" direction="column" p="4">
          <Stack direction="row" w="100%">
            <Box w="500px">
              <SelectComponent
                name="selectTemplate"
                placeholder={t('placeholderTemplate')}
                options={templates?.map((temp) => {
                  return {
                    label: temp.name,
                    value: temp.id
                  }
                })}
                value={selectTemplate || defaultTemplate}
                onChange={(event: any) => {
                  setSelectTemplate(event)
                  setDefaultTemplate(event)
                }}
              />
            </Box>
            {typePrint !== 'indication' ||
              ('quotes' && (
                <Box w="220px">
                  <Select
                    name="typeDocument"
                    options={optionsDocument}
                    value={typeDocument}
                    onChange={(event) => setTypeDocumento(event.target.value)}
                  />
                </Box>
              ))}
            <Box w="220px">
              <Select
                name="paperType"
                options={optionsPaper}
                value={paperType}
                onChange={(event) => setPaperType(event.target.value)}
              />
            </Box>
          </Stack>
          <Stack direction="row" w="100%" spacing={2}>
            <Box>
              {typePrint === 'quotes' ? (
                <Switch
                  name="isPrintDiscount"
                  label={t('isDiscount')}
                  isChecked={isPrintDiscount}
                  onChange={(event) => setIsPrintDiscount(event.target.checked)}
                />
              ) : (
                <Switch
                  name="isPrintDate"
                  label={t('isDate')}
                  isChecked={isPrintDate}
                  onChange={(event) => setIsPrintDate(event.target.checked)}
                />
              )}
            </Box>
            {isPrintDate && typePrint !== 'quotes' && (
              <Box w="220px">
                <Input
                  name="date"
                  isRequired
                  label={t('date')}
                  value={date}
                  type="date"
                  onChange={(event) => setDate(event.target.value)}
                />
              </Box>
            )}
          </Stack>
        </Stack>
      </ModalArrivedTime>
      {isLoading && (
        <Center
          w="700px"
          h="200px"
          top="40%"
          left="28%"
          zIndex={999}
          bg="#12121448"
          position="fixed"
        >
          <Stack direction="row" alignItems="center" justifyContent="Center">
            <Text fontSize="22px" color="white">
              Gerando documento para impressão
            </Text>
            <Spinner size="lg" color="white" />
          </Stack>
        </Center>
      )}
      <ModalDocument
        onClose={() => setIsOpenDocument(false)}
        isOpen={isOpenDocument}
        src={document || ''}
      />
    </>
  )
}
