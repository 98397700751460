/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import {
  Box,
  Button,
  Checkbox,
  CloseButton,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  List,
  ListItem,
  Spinner,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Tooltip,
  UnorderedList,
  useToast,
  VStack
} from '@chakra-ui/react'
import format from 'date-fns/format'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BiReset } from 'react-icons/bi'
import { FiEye, FiEyeOff, FiUser } from 'react-icons/fi'
import { Link, useHistory, useParams } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'
import { Element } from '../../components/DynamicForms/Element'
import { Input } from '../../components/Form/Input'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { MasksTypes } from '../../components/Form/TextFieldControlled'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { ModalConfirmExclude } from '../../components/Modal/confirmExclude'
import { ModalContentComponent } from '../../components/Modal/modalContent'
import { CardContainer } from '../../components/molecules/CardContainer'
import { Draft } from '../../components/molecules/Draft'
import { PrintButton } from '../../components/PrintButton'
import { useAuth } from '../../hooks/auth'
import { useModal } from '../../hooks/useModal'
import { LayoutDefault } from '../../layouts/Default'
import { apiAuth } from '../../services/apiAuth'
import { useLogic } from './logic'
import { useService } from './services'

interface ParamsProps {
  id: string
}

type DocumentClient = {
  acronym: string
  id: number
  index: Number
  is_required: boolean
  is_view: boolean
  name_en: string
  name_es: string
  name_ptbr: string
  regex: MasksTypes
  value: string
  valueMask: string
}

interface AttendanceInfos {
  id: number
  guide: number
  patient_id: number
  edit_status: 1 | 2 | 3
  hash: string
  allowed_start_attendance: boolean
  block_checkout: boolean
  status_by_system: boolean
  patient_documents: Array<DocumentClient>
  scheduled_arrival_time: string
  scheduled_initial_time: string
  scheduled_final_time: string
  real_arrival_time: string
  real_initial_time: string
  real_final_time: string
  status: number
  patient_birth_date?: string
  professional_id: number
  attendance_spot_id: number
  observation: string
  observation_payment: string
  value_to_income: number
  patient_number?: string
  patient_email?: string
  invoice_number: number | null
  invoice_date: string | null
  block_guide: boolean
  is_active: boolean
  specialist_name: string | null
  patient_name: string | null
  sessions: Array<{
    id: number
    session_number: number
    status: number
    description: string | null
    attendance_id: number
    service_id: number
    service_name: string
    total_session: number
  }>
  sessions_not_performed: Array<{}>
  indications: Array<{}>
  patient_social_name: string | null
  attendance_items_exclude?: Array<number>
  specialist_color: null | string
  ticket_status_payment?: number | null
  attendance_items: Array<{
    id: number
    service_name: string
    service_id: number
    total_session: number
  }>
  attendance_spot: {
    id: number
    index: number
    name: string
    is_active: boolean
  }
}

interface Questions {
  question: string
  options: string | undefined
  is_required: boolean
  is_multiple: boolean
  answer: string | undefined
}

type QuestionsTokens = Array<Questions>

type AttendanceData = AttendanceInfos | null

type ExcludeType = 'token' | 'exam' | 'prescription'

type ServicesPerformed = {
  serviceName: string
  serviceId: number
  fulfilled: boolean
  total_session: number
  description: string | undefined
}

type SessionsPerformed = {
  sessionId: number
  fulfilled: boolean
  description: string | undefined
  serviceName: string
}

type ServicesPerformedData = Array<ServicesPerformed>

type SessionsPerformedData = Array<SessionsPerformed>

type Template = {
  id: number
  name: string
}

type Templates = Array<Template>

type DraftValueApi = {
  start_date: string
  finish_date: string | null
  is_draft: boolean
  forms?: Array<{
    id: number
    name: string
    information: string
  }>
  prescriptions: Array<{
    id: number
    name: string
    information: string
  }>
  exams: Array<{
    id: number
    name: string
    information: string
  }>
  indications: Array<{
    id: number
    service: {
      id: number
      name: string
      price: string
    }
    service_name: string
    observation: string
    status: number
    indication_date: string
    specialist_name: string
    medical_record_finish_date: string | null
    updated_at: string
    updated_by_name: string | null
  }>
  attendance_id: number
}

type Documents = {
  id: number
  name_ptbr: string
  name_en: string
  acronym: string
  index: Number
  regex: MasksTypes
}

type ClinicData = {
  patient_birth_date: boolean
  patient_document: boolean
  patient_origin: boolean
  patient_view_email: boolean
  hours_attendance_start: null | number
  hours_attendance_cancel: null | number
  hours_attendance_finish: null | number
  adding_itens_to_attendance: boolean
  documents: Documents[]
  documents_preferences: Array<any>
}

// debounce
let timer: any = null

export const AttendanceScreen: React.FC = () => {
  const [t] = useTranslation('pageAttendanceScreen')
  const { decodeToken, clinicId } = useAuth()
  const toast = useToast()
  const router = useHistory()
  const { id } = useParams<ParamsProps>()
  const { setTabIndexClient } = useModal()
  const services = useService()
  const logic = useLogic()
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { state }: any = router.location
  const { errors } = formState
  const [templates, setTemplates] = useState<Templates>([])
  const [attendance, setAttendance] = useState<AttendanceData>(null)
  const [dataDraft, setDataDraft] = useState<DraftValueApi | null>(null)
  const [isLoadingSave, setIsLoadingSave] = useState(false)
  const [ageUser, setAgeUser] = useState('')
  const [isShow, setIsShow] = useState(true)
  const [medicalObservation, setMedicalObservation] = useState('')
  const [isViewEmail, setIsViewEmail] = useState(false)

  // back infos?
  const [isBackInfo, setIsBackInfo] = useState(false)

  // services complete
  const [servicesPerformed, setServicesPerformed] =
    useState<ServicesPerformedData>([])

  // sessions complete
  const [sessionsPerformed, setSessionsPerformed] =
    useState<SessionsPerformedData>([])

  // modal Registro clínicos
  const [isOpen, setIsOpen] = useState(false)

  // modal finished
  const [isFinished, setIsFinished] = useState(false)

  // modal exclude itens
  const [isExcluded, setIsExcluded] = useState(false)
  const [indexExclude, setIndexExclude] = useState(0)
  const [typeExclude, setTypeExclude] = useState<ExcludeType>('token')
  // get documents options
  useEffect(() => {
    apiAuth.get<ClinicData>(`clinics/clinics/${clinicId}/`).then(({ data }) => {
      if (attendance !== undefined) {
        if (data?.patient_view_email) {
          setIsViewEmail(data?.patient_view_email)
        }
        if (attendance?.patient_documents !== undefined) {
          if (attendance?.patient_documents?.length > 0) {
            const layoutDocument = data?.documents_preferences?.map((doc) => {
              const getDocument = attendance?.patient_documents?.find(
                (docClient) => docClient.id === doc.id
              )

              return {
                acronym: doc.document?.acronym,
                id: doc?.id,
                index: doc?.document?.index,
                is_required: doc?.is_required,
                is_view: doc?.is_view,
                name_en: doc?.document?.name_en,
                name_es: doc?.document?.name_en,
                name_ptbr: doc?.document?.name_ptbr,
                regex: doc?.document?.regex,
                value: getDocument?.value || '',
                valueMask: getDocument?.valueMask || ''
              }
            })

            const allAttendance = { ...attendance }
            allAttendance['patient_documents'] = layoutDocument

            setAttendance(allAttendance)
          }
        }
      }
    })

    return () => {}
  }, [])

  // modal functions
  function openModalExclude() {
    setIsExcluded(true)
  }

  function closeModalExclude() {
    setIsExcluded(false)
  }

  function openModalHistory() {
    setIsOpen(true)
  }

  function closeModalHistory() {
    setIsOpen(false)
  }

  function openModalFinished() {
    setIsFinished(true)
  }

  function closeModalFinished() {
    setIsFinished(false)
  }

  // services handler action finalizer
  function handlerChangeFulfilled(
    idService: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const allServicesPerFormed = [...servicesPerformed]

    const data = {
      fulfilled: event.target.checked
    }

    const newValuesPerFormed = allServicesPerFormed.map((item) => {
      return item.serviceId === idService ? { ...item, ...data } : item
    })

    setServicesPerformed(newValuesPerFormed)
  }

  // change description services not performed
  function handlerChangeDescription(
    idService: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const allServicesPerFormed = [...servicesPerformed]

    const data = {
      description: event.target.value
    }

    const newValuesPerFormed = allServicesPerFormed.map((item) => {
      return item.serviceId === idService ? { ...item, ...data } : item
    })

    setServicesPerformed(newValuesPerFormed)
  }

  // sessions finalization
  function handlerChangeFulfilledSessions(
    idSession: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const allSessions = [...sessionsPerformed]

    const data = {
      fulfilled: event.target.checked
    }

    const newValuesPerFormed = allSessions.map((item) => {
      return item.sessionId === idSession ? { ...item, ...data } : item
    })

    setSessionsPerformed(newValuesPerFormed)
  }

  // change descriptions sessions not performed
  function handlerChangeDescriptionSessions(
    idSession: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const allSessions = [...sessionsPerformed]

    const data = {
      description: event.target.value
    }

    const newValuesPerFormed = allSessions.map((item) => {
      return item.sessionId === idSession ? { ...item, ...data } : item
    })

    setSessionsPerformed(newValuesPerFormed)
  }

  function defineNameDocumentByLanguage(document: DocumentClient) {
    if (decodeToken?.clinic_language === 'en') {
      return document?.name_ptbr
    }

    if (decodeToken?.clinic_language === 'es') {
      return document?.name_es
    }

    return document?.name_ptbr
  }

  // get templates for print
  // buscar no component pai para evitar várias chamadas pra pegar esse valor
  useEffect(() => {
    const getDataTemplates = async () => {
      const { data } = await apiAuth.get<Templates>(
        `clinics/prescription-email-templates/`,
        {
          params: {
            noPaginate: 1,
            is_active: true
          }
        }
      )

      setTemplates(data)
    }

    getDataTemplates()

    return () => {}
  }, [])

  // loading infos attendance useEffect
  useEffect(() => {
    const getAttendance = () => {
      apiAuth.get(`clinics/attendances/${id}/`).then((response) => {
        setAttendance(response.data)
        setMedicalObservation(response?.data?.patient_alerts || '')
      })
    }
    getAttendance()

    return () => {}
  }, [id])

  // loading infos is_Draft
  useEffect(() => {
    const getMedicalRecords = () => {
      apiAuth
        .get(`clinics/attendances/${id}/medical-records/`)
        .then((response) => {
          // forms
          setDataDraft(response?.data)
          const { forms, prescriptions, exams, indications } = response.data

          const backValuesForms = forms?.map((form: any) => {
            const getFormSelect = services.formsComplete.find((AllForms) => {
              return AllForms.name === form.name
            })

            const selectedBackValue = {
              label: getFormSelect?.name,
              value: getFormSelect?.id
            }

            getFormSelect?.groups?.forEach((group) => {
              const { fields } = group

              fields.forEach((field) => {
                for (let index = 0; index < form.questions.length; index++) {
                  if (field.label === form.questions[index].question) {
                    if (field.type === 'radio') {
                      const answerArray =
                        form?.questions[index]?.answer !== undefined &&
                        form?.questions[index]?.answer?.split(',')

                      field['value'] = form.questions[index]?.answer || ''
                      field['default_value'] = answerArray || undefined
                    } else {
                      field['value'] = form.questions[index]?.answer || ''
                      field['default_value'] =
                        form.questions[index]?.answer || undefined
                    }
                  }
                }
              })
            })

            return {
              id: uuidV4(),
              isChange: false,
              isVisible: true,
              baseContent: form?.information || null,
              isPreference: true,
              selected: getFormSelect ? selectedBackValue : null,
              form: getFormSelect || undefined
            }
          })

          const backValuesFormsBlank = backValuesForms.map((item: any) => {
            return {
              id: uuidV4(),
              content: item?.baseContent || '',
              backContentRender: item?.baseContent || undefined
            }
          })

          // prescriptions back values
          const backValuesPrescriptions = prescriptions?.map((pres: any) => {
            const getPrescriptionsSelect = services.prescriptionsComplete.find(
              (allPres) => {
                return allPres.name === pres.name
              }
            )

            const selectedBackValue = {
              label: getPrescriptionsSelect?.name,
              value: getPrescriptionsSelect?.id
            }

            return {
              id: uuidV4(),
              isChange: false,
              isVisible: true,
              baseContent: pres?.information || null,
              isPreference: true,
              selected: getPrescriptionsSelect ? selectedBackValue : null
            }
          })

          const backValuesPrescriptionsBlank = backValuesPrescriptions.map(
            (itemP: any) => {
              return {
                id: uuidV4(),
                content: itemP?.baseContent || '',
                backContentRender: itemP?.baseContent || undefined
              }
            }
          )

          // exams back values
          const backValuesExams = exams?.map((exam: any) => {
            const getExamSelect = services.examsComplete.find((allExams) => {
              return allExams.name === exam.name
            })

            const selectedBackValue = {
              label: getExamSelect?.name,
              value: getExamSelect?.id
            }

            return {
              id: uuidV4(),
              isChange: false,
              isVisible: true,
              baseContent: exam?.information || null,
              isPreference: true,
              selected: getExamSelect ? selectedBackValue : null
            }
          })

          const backValuesExamsBlank = backValuesExams.map((itemP: any) => {
            return {
              id: uuidV4(),
              content: itemP?.baseContent || '',
              backContentRender: itemP?.baseContent || undefined
            }
          })

          // indications
          const backValuesIndications = indications.map((ind: any) => {
            const service = {
              value: ind?.service?.id,
              label: ind?.service_name
            }

            const dateIndication = ind?.indication_date?.substring(0, 10)

            return {
              id: uuidV4(),
              service: service || null,
              observation: ind?.observation || '',
              indicationDate: dateIndication
            }
          })

          logic.setTokens(backValuesForms)
          logic.setContentsT(backValuesFormsBlank)
          logic.setPrescriptions(backValuesPrescriptions)
          logic.setContentsP(backValuesPrescriptionsBlank)
          logic.setExamsRequest(backValuesExams)
          logic.setContentsE(backValuesExamsBlank)
          logic.setIndications(backValuesIndications)
          setIsBackInfo(true)
        })
    }

    getMedicalRecords()
  }, [
    services.formsComplete,
    services.prescriptionsComplete,
    services.examsComplete
  ])

  // save medical observation
  useEffect(() => {
    if (attendance === null) {
      return
    }

    const handleChangeInfosMedicalObservation = () => {
      const payload = {
        alerts: medicalObservation,
        name: attendance.patient_name,
        social_name: attendance.patient_social_name
      }
      apiAuth.put(`clinics/patients/${attendance.patient_id}/`, payload)
    }

    clearTimeout(timer)

    timer = setTimeout(() => {
      handleChangeInfosMedicalObservation()
    }, 500)
  }, [medicalObservation])

  // calculateAge functions
  function fragmentDate(data: string) {
    const dia = Number(data.substring(0, 2))
    const mes = Number(data.substring(3, 5))
    const ano = Number(data.substring(6, 12))

    return {
      dia,
      mes,
      ano
    }
  }

  function calculateAge(data: string) {
    // data atual
    const dateCurrent = new Date()
    const yearCurrent = dateCurrent.getFullYear()
    const mothCurrent = dateCurrent.getMonth() + 1
    const dayCurrent = dateCurrent.getDate()

    // data nascimento
    const { dia, mes, ano } = fragmentDate(data)

    let years = Number(yearCurrent) - Number(ano)

    if (mothCurrent < mes || (mothCurrent === mes && dayCurrent < dia)) {
      years -= 1
    }

    return years < 0 ? 0 : years
  }

  // calculate age maker servicesPerformed
  useEffect(() => {
    if (attendance === null) {
      return
    }

    const allServices: ServicesPerformedData = attendance.attendance_items.map(
      (service) => {
        return {
          serviceId: service.service_id,
          serviceName: service.service_name,
          fulfilled: true,
          description: undefined,
          total_session: service?.total_session
        }
      }
    )

    const allSessions: SessionsPerformedData = attendance?.sessions?.map(
      (sec) => {
        return {
          description: undefined,
          fulfilled: true,
          serviceName: `${sec?.service_name} - ${sec?.session_number}/${sec?.total_session}`,
          sessionId: sec?.id
        }
      }
    )

    setServicesPerformed(allServices)

    setSessionsPerformed(allSessions)

    if (attendance.patient_birth_date === null) {
      return
    }

    const date = format(
      new Date(
        Number(attendance.patient_birth_date?.substring(0, 4)),
        Number(attendance.patient_birth_date?.substring(5, 7)) - 1,
        Number(attendance.patient_birth_date?.substring(8, 10))
      ),
      'dd/MM/yyyy'
    )

    const age = calculateAge(date)
    setAgeUser(String(age))
  }, [attendance])

  function countTokens() {
    let count = 0

    const allTokens = logic.tokens.map((token, index) => {
      let questions: QuestionsTokens = []

      if (token?.selected !== null) {
        count += 1
      }

      token?.form?.groups?.forEach((group) => {
        group.fields.forEach((field) => {
          const choices = field.choices?.map((choice) => {
            return choice.label
          })

          const options = choices?.join(',') || undefined

          questions = [
            ...questions,
            {
              question: field.label,
              is_required: field.is_required,
              options,
              is_multiple: field.type === 'radio',
              answer: field.value || undefined
            }
          ]
        })
      })

      return {
        name: token.form?.name || 'Blank',
        information: logic.contentsToken[index]?.content || null,
        questions
      }
    })

    allTokens.forEach((token) => {
      if (token?.information !== null) {
        if (token?.information !== '<p></p>') {
          if (token?.information !== '<p></p>\n') {
            count += 1
          }
        }
      }
    })

    if (count === 0) {
      return ''
    }

    return count
  }

  function countPrescriptions() {
    let count = 0

    const allFormatPrescriptions = logic.prescriptions.map(
      (prescription, index) => {
        return {
          name: prescription.selected?.label || 'Blank',
          information: logic.contentsPrescriptions[index]?.content || null
        }
      }
    )

    allFormatPrescriptions.forEach((token) => {
      if (token?.information !== null) {
        if (token?.information !== '<p></p>') {
          if (token?.information !== '<p></p>\n') {
            count += 1
          }
        }
      }
    })

    if (count === 0) {
      return ''
    }

    return count
  }

  function countExams() {
    let count = 0

    const allFormatExams = logic.examsRequest.map((exam, index) => {
      return {
        name: exam.selected?.label || 'Blank',
        information: logic.contentsExams[index]?.content || null
      }
    })

    allFormatExams.forEach((token) => {
      if (token?.information !== null) {
        if (token?.information !== '<p></p>') {
          if (token?.information !== '<p></p>\n') {
            count += 1
          }
        }
      }
    })

    if (count === 0) {
      return ''
    }

    return count
  }

  // save is_draft in backEnd
  useEffect(() => {
    setIsLoadingSave(true)
    clearTimeout(timer)

    timer = setTimeout(() => {
      // format For finalizers MedicalRecords and isDraft = false
      // tokens
      function formatTokensEffect() {
        const allTokens = logic.tokens.map((token, index) => {
          let questions: QuestionsTokens = []

          token?.form?.groups?.forEach((group) => {
            group.fields.forEach((field) => {
              const choices = field.choices?.map((choice) => {
                return choice.label
              })

              const options = choices?.join(',') || undefined

              questions = [
                ...questions,
                {
                  question: field.label,
                  is_required: field.is_required,
                  options,
                  is_multiple: field.type === 'radio',
                  answer: field.value || undefined
                }
              ]
            })
          })

          return {
            name: token.form?.name || 'Blank',
            information: logic.contentsToken[index]?.content || null,
            questions
          }
        })

        return allTokens
      }

      // prescriptions
      function formatPrescriptionsEffect() {
        const allFormatPrescriptions = logic.prescriptions.map(
          (prescription, index) => {
            return {
              name: prescription.selected?.label || 'Blank',
              information: logic.contentsPrescriptions[index]?.content || null
            }
          }
        )

        return allFormatPrescriptions
      }

      // exams
      function formatExamsEffect() {
        const allFormatExams = logic.examsRequest.map((exam, index) => {
          return {
            name: exam.selected?.label || 'Blank',
            information: logic.contentsExams[index]?.content || null
          }
        })

        return allFormatExams
      }

      // indications
      function formatIndicationsEffect() {
        const allFormatIndications = logic.indications.map((indication) => {
          if (indication.service?.value) {
            return {
              service_name: indication.service?.label || undefined,
              observation: indication.observation || null,
              indication_date: `${indication?.indicationDate}T00:00:00`,
              status: 1,
              service: {
                id: indication.service?.value || undefined
              }
            }
          }
          return undefined
        })

        // remove arrays invalides
        const filterIndications = allFormatIndications.filter(Boolean)

        return filterIndications || []
      }

      const prescriptions = formatPrescriptionsEffect()
      const exams = formatExamsEffect()
      const indications = formatIndicationsEffect()
      const forms = formatTokensEffect()

      const isDraftPayload = {
        prescriptions,
        exams,
        forms,
        indications
      }

      if (isBackInfo) {
        apiAuth
          .patch(`clinics/attendances/${id}/medical-records/`, isDraftPayload)
          .then((response) => {
            setDataDraft(response?.data)

            toast({
              duration: 2000,
              status: 'success',
              title: `${t('autosave.success')}`
            })
          })
          .catch((err: any) => {
            toast({
              duration: 2000,
              status: 'error',
              title: `${t('autosave.error')}`
            })
          })
      }

      setIsLoadingSave(false)
    }, 850)
  }, [
    logic.tokens,
    logic.indications,
    logic.examsRequest,
    logic.prescriptions,
    logic.contentsExams,
    logic.contentsPrescriptions,
    logic.contentsToken
  ])

  // format For finalizers MedicalRecords and isDraft = false
  // tokens
  function formatTokens() {
    const allTokens = logic.tokens.map((token, index) => {
      let questions: QuestionsTokens = []

      token?.form?.groups?.forEach((group) => {
        group.fields.forEach((field) => {
          const choices = field.choices?.map((choice) => {
            return choice.label
          })

          const options = choices?.join(',') || undefined

          questions = [
            ...questions,
            {
              question: field.label,
              is_required: field.is_required,
              options,
              is_multiple: field.type === 'radio',
              answer: field.value || undefined
            }
          ]
        })
      })

      return {
        name: token.form?.name || 'Blank',
        information: logic.contentsToken[index].content || null,
        questions
      }
    })

    const validationTokens = allTokens?.map((token) => {
      if (token?.questions?.length > 0) {
        return token
      }

      if (token?.questions?.length <= 0 && token?.information === null) {
        return false
      }

      if (token?.questions?.length <= 0 && token?.information === '<p></p>\n') {
        return false
      }

      if (token?.questions?.length <= 0 && token?.information === '<p></p>') {
        return false
      }

      if (token?.information === null) {
        return false
      }

      if (token?.information === '<p></p>\n') {
        return false
      }

      return token
    })

    const returnToken = validationTokens?.filter(Boolean)

    return returnToken
  }

  // prescriptions
  function formatPrescriptions() {
    const allFormatPrescriptions = logic.prescriptions.map(
      (prescription, index) => {
        return {
          name: prescription.selected?.label || 'Blank',
          information: logic.contentsPrescriptions[index].content || null
        }
      }
    )

    const validationPrescriptions = allFormatPrescriptions?.map((token) => {
      if (token?.information === null) {
        return false
      }

      if (token?.information === '<p></p>\n') {
        return false
      }

      if (token?.information === '<p></p>') {
        return false
      }

      return token
    })

    const returnPrescriptions = validationPrescriptions?.filter(Boolean)

    return returnPrescriptions
  }

  // exams
  function formatExams() {
    const allFormatExams = logic.examsRequest.map((exam, index) => {
      return {
        name: exam.selected?.label || 'Blank',
        information: logic.contentsExams[index].content || null
      }
    })

    const validationExams = allFormatExams?.map((token) => {
      if (token?.information === null) {
        return false
      }

      if (token?.information === '<p></p>\n') {
        return false
      }

      if (token?.information === '<p></p>') {
        return false
      }

      return token
    })

    const returnExams = validationExams?.filter(Boolean)

    return returnExams
  }

  // indications
  function formatIndications() {
    const allFormatIndications = logic.indications.map((indication) => {
      if (indication.service?.value) {
        return {
          service_name: indication.service?.label || undefined,
          observation: indication.observation || null,
          indication_date: `${indication?.indicationDate}T00:00:00`,
          status: 1,
          service: {
            id: indication.service?.value || undefined
          }
        }
      }
      return undefined
    })

    // remove arrays invalides
    const filterIndications = allFormatIndications.filter(Boolean)

    return filterIndications || []
  }

  // services not performed
  function formatServicesNotPerformed() {
    const allServicesNotPerformed = servicesPerformed.map((item) => {
      if (item.fulfilled === false) {
        return {
          service: {
            id: item?.serviceId || undefined
          },
          service_name: item?.serviceName || undefined,
          description: item?.description || null
        }
      }

      return undefined
    })

    const allServices = allServicesNotPerformed.filter(Boolean)

    return allServices
  }

  // services not performed
  function formatSessionsNotPerformed() {
    const allSessionsNotPerformed = sessionsPerformed.map((item) => {
      if (item.fulfilled === false) {
        return {
          id: item?.sessionId,
          description: item?.description ?? undefined
        }
      }

      return undefined
    })

    const allSessions = allSessionsNotPerformed.filter(Boolean)

    return allSessions
  }

  const handleFinalizeAttendance = async () => {
    let isInvalid = false
    const prescriptions = formatPrescriptions()
    const exams = formatExams()
    const indicationsValue = formatIndications()
    const forms = formatTokens()
    const services_not_performed = formatServicesNotPerformed()
    const sessions_not_performed = formatSessionsNotPerformed()

    services_not_performed.forEach((item) => {
      if (!item?.description) {
        isInvalid = true
        toast({
          description: `Descrição de serviços não realizado é obrigatória`,
          duration: 2000,
          status: 'error',
          title: 'Aconteceu um erro!'
        })
      }
    })

    sessions_not_performed.forEach((item) => {
      if (!item?.description) {
        isInvalid = true
        toast({
          description: `Descrição de sessões não realizadas é obrigatória`,
          duration: 2000,
          status: 'error',
          title: 'Aconteceu um erro!'
        })
      }
    })

    if (isInvalid) {
      return
    }

    const sessionsNewDescription = attendance?.sessions?.map((item) => {
      let description = item?.description

      const session = sessions_not_performed?.find(
        (session) => session?.id === item?.id
      )

      if (session) {
        description = session?.description ?? ''
      }

      const newData = {
        description
      }

      return {
        ...item,
        ...newData
      }
    })

    const data = {
      status: 7,
      patient_id: attendance?.patient_id,
      real_final_time: format(new Date(), "yyyy-MM-dd'T'hh':'mm':'ss"),
      observation: logic.observation || null,
      attendance_items: attendance?.attendance_items,
      attendance_spot: attendance?.attendance_spot,
      services_not_performed,
      indications: attendance?.indications,
      sessions: sessionsNewDescription,
      sessions_not_performed
    }

    const payloadMedicalRecords = {
      finish_date: format(new Date(), "yyyy-MM-dd'T'hh':'mm':'ss"),
      is_draft: false,
      prescriptions,
      exams,
      forms,
      indications: indicationsValue
    }

    await apiAuth.put(`clinics/attendances/${id}/`, data)

    try {
      await apiAuth.patch(
        `clinics/attendances/${id}/medical-records/`,
        payloadMedicalRecords
      )

      await apiAuth.put(`clinics/attendances/${id}/`, data)

      toast({
        description: `Atendimento e Registro clínico,
        finalizados e salvos com sucesso, estamos lhe direcionando!`,
        duration: 3000,
        status: 'success',
        title: 'Atendimento Finalizado!'
      })

      closeModalFinished()

      setTimeout(() => {
        router.push(`/clients/update/${attendance?.patient_id}`)
      }, 2000)
    } catch (error: any) {
      toast({
        description: `${error.response?.data?.message}`,
        duration: 3000,
        status: 'error',
        title: 'Aconteceu um erro!'
      })
    }
  }

  const handlerChangeFormValues = (idField: number, event: any) => {
    const newTokens = [...logic.tokens]
    newTokens.forEach((token) => {
      token.form?.groups.forEach((group) => {
        group.fields.forEach((field) => {
          if (field.id === idField) {
            if (field.type === 'radio') {
              field['default_value'] = event
              field['value'] = event.join(',')
            } else {
              field['value'] = event?.target.value
            }
          }
        })
      })
    })

    logic.setTokens(newTokens)
  }

  if (attendance === null) {
    return (
      <LayoutDefault
        IconHeader={<FiUser />}
        title={t('title')}
        urlBack="/clients"
      >
        <Flex w="100%" h="100vh" justify="center" align="center">
          <Spinner color="blue" />
        </Flex>
      </LayoutDefault>
    )
  }

  return (
    <>
      <LayoutDefault
        title={
          attendance?.patient_social_name
            ? `${t('title')} (${attendance?.patient_social_name})`
            : `${t('title')} (${attendance?.patient_name})`
        }
        IconHeader={<FiUser />}
        onSubmit={undefined}
        urlBack={`/clients/update/${attendance?.patient_id}`}
        isTimer
        timer={
          Math.round(new Date().getTime() / 1000) -
          Math.round(new Date(attendance?.real_initial_time).getTime() / 1000) -
          10800
        }
      >
        <Stack justify="space-evenly" spacing="4" w="100%">
          <CardContainer title={t('container1.title')}>
            <Stack
              position="absolute"
              alignItems="center"
              justifyContent="center"
              direction="row"
              spacing={4}
              top="2"
              right="4"
            >
              <a
                href={`${window.location.origin}/clients/update/${attendance?.patient_id}`}
                target="_blank"
              >
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  _hover={{ cursor: 'pointer' }}
                >
                  {t('links.all')}
                </Text>
              </a>
              <Flex
                bg={isShow ? 'green.500' : 'red.500'}
                w="20px"
                h="20px"
                alignItems="center"
                justifyContent="center"
                borderRadius="full"
              >
                <Icon
                  color="white"
                  as={isShow ? FiEye : FiEyeOff}
                  onClick={() => setIsShow(!isShow)}
                />
              </Flex>
            </Stack>
            {isShow && (
              <VStack w="100%" spacing="4">
                <HStack w="100%" spacing="4">
                  <Text fontWeight="hairline">{t('container1.name')}</Text>
                  <Text fontWeight="hairline">{attendance?.patient_name}</Text>
                  <Text fontWeight="hairline">{t('container1.birthDate')}</Text>
                  {attendance?.patient_birth_date ? (
                    <Text fontWeight="hairline">
                      {format(
                        new Date(
                          Number(
                            attendance?.patient_birth_date.substring(0, 4)
                          ),
                          Number(
                            attendance?.patient_birth_date.substring(5, 7)
                          ) - 1,
                          Number(
                            attendance?.patient_birth_date.substring(8, 10)
                          )
                        ),
                        'dd/MM/yyyy'
                      )}
                    </Text>
                  ) : (
                    <Text fontWeight="hairline">---</Text>
                  )}
                  <Text fontWeight="hairline">{t('container1.age')}</Text>
                  <Text fontWeight="hairline">{ageUser}</Text>
                  <Text fontWeight="hairline">{t('container1.email')}</Text>
                  <Text fontWeight="hairline">{attendance?.patient_email}</Text>
                </HStack>
                <VStack
                  w="100%"
                  rounded={12}
                  p="2"
                  borderWidth={1}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  spacing="4"
                >
                  <Text fontWeight="hairline">{t('container1.documents')}</Text>
                  {attendance?.patient_documents?.map((doc) => {
                    if (doc?.is_view) {
                      return (
                        <Flex gap={4} key={doc?.id} flexDirection="row">
                          <Text fontWeight="bold">
                            {defineNameDocumentByLanguage(doc)}:
                          </Text>
                          <Text fontWeight="hairline">{`${doc?.valueMask}`}</Text>
                        </Flex>
                      )
                    }
                  })}
                </VStack>
                <VStack
                  w="100%"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  spacing="4"
                >
                  <Text fontWeight="hairline">
                    {t('container1.observationClient')}
                  </Text>
                  <Textarea
                    name="observationClient"
                    value={medicalObservation}
                    onChange={(e) => setMedicalObservation(e.target.value)}
                  />
                </VStack>
                <Flex w="100%" flexDirection="column">
                  <Text mb="2" mr="2" fontSize="md" fontWeight="hairline">
                    {t('container1.observationAttendance')}
                  </Text>
                  <Text fontSize="md">{attendance?.observation}</Text>
                </Flex>
                <Flex flexDirection="column" w="100%">
                  <Text my="2">{t('container1.services')}</Text>
                  <UnorderedList>
                    {attendance.attendance_items.length > 0 &&
                      attendance.attendance_items.map((service) => {
                        return (
                          <ListItem fontSize="md" key={service.service_id}>
                            {`${service?.service_name} - 1/${service?.total_session}`}
                          </ListItem>
                        )
                      })}
                    {attendance.sessions.length > 0 &&
                      attendance.sessions.map((sec) => {
                        return (
                          <ListItem fontSize="md" key={sec.id}>
                            {`${sec?.service_name} - ${sec?.session_number}/${sec?.total_session}`}
                          </ListItem>
                        )
                      })}
                  </UnorderedList>
                </Flex>
              </VStack>
            )}
          </CardContainer>

          {/* tabs */}
          <Tabs
            isFitted
            defaultIndex={0}
            variant="soft-rounded"
            colorScheme="blue"
            w="100%"
          >
            <TabList>
              <Tab>
                Fichas{' '}
                {countTokens() > 0 && (
                  <Flex
                    p="2px"
                    w="20px"
                    h="20px"
                    alignItems="center"
                    justifyContent="center"
                    bg="red.500"
                    color="white"
                    mx="2"
                    borderRadius="full"
                  >
                    {countTokens()}
                  </Flex>
                )}
              </Tab>
              <Tab>
                Receitas{' '}
                {countPrescriptions() > 0 && (
                  <Flex
                    p="2px"
                    w="20px"
                    h="20px"
                    alignItems="center"
                    justifyContent="center"
                    bg="red.500"
                    color="white"
                    mx="2"
                    borderRadius="full"
                  >
                    {countPrescriptions()}
                  </Flex>
                )}
              </Tab>
              <Tab>
                Solicitações de exames{' '}
                {countExams() > 0 && (
                  <Flex
                    p="2px"
                    w="20px"
                    h="20px"
                    alignItems="center"
                    justifyContent="center"
                    bg="red.500"
                    color="white"
                    mx="2"
                    borderRadius="full"
                  >
                    {countExams()}
                  </Flex>
                )}
              </Tab>
              <Tab>
                Indicações{' '}
                {logic.indications.length > 0 && (
                  <Flex
                    p="2px"
                    w="20px"
                    h="20px"
                    alignItems="center"
                    justifyContent="center"
                    bg="red.500"
                    color="white"
                    mx="2"
                    borderRadius="full"
                  >
                    {logic.indications.length}
                  </Flex>
                )}
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <CardContainer title={t('container3.title')}>
                  {isLoadingSave && (
                    <Flex position="absolute" top="2" right="4">
                      <Text fontSize="12" fontWeight="hairline">
                        {t('autosave.message')}
                      </Text>
                    </Flex>
                  )}
                  <VStack spacing="4" w="100%" mb="2">
                    {logic.tokens.length > 0 &&
                      logic.tokens.map((item, index: number) => (
                        <>
                          <Flex
                            flexDirection="column"
                            w="100%"
                            justifyContent="flex-start"
                            key={item.id}
                          >
                            <Flex
                              w="100%"
                              alignItems="center"
                              justifyContent="flex-start"
                              gap={4}
                              mb="6px"
                            >
                              <SelectComponent
                                name="tokenSelected"
                                placeholder={t(
                                  'container3.placeholder.content'
                                )}
                                options={
                                  item.isPreference === true
                                    ? services.formsSelectPreference
                                    : services.formsSelect
                                }
                                value={item.selected}
                                onChange={(event: any) =>
                                  logic.handlerSelectFormToken(item.id, event)
                                }
                              />
                              <Tooltip
                                label={t('preference')}
                                aria-label="preference"
                                hasArrow
                                placement="top-start"
                              >
                                <Box>
                                  <Switch
                                    name="tokensIsPreference"
                                    isChecked={item.isPreference}
                                    onChange={(event) =>
                                      logic.handlerClickIsPreferenceToken(
                                        item.id,
                                        item.isPreference
                                      )
                                    }
                                  />
                                </Box>
                              </Tooltip>
                              {item.isVisible ? (
                                <Icon
                                  as={FiEye}
                                  _hover={{ color: 'red', cursor: 'pointer' }}
                                  fontSize="20px"
                                  onClick={() => {
                                    logic.handlerClickIsVisibleToken(
                                      item.id,
                                      item.isVisible
                                    )
                                  }}
                                />
                              ) : (
                                <Icon
                                  as={FiEyeOff}
                                  _hover={{ color: 'green', cursor: 'pointer' }}
                                  fontSize="20px"
                                  onClick={() => {
                                    logic.handlerClickIsVisibleToken(
                                      item.id,
                                      item.isVisible
                                    )
                                  }}
                                />
                              )}
                              <Tooltip
                                label={t('container3.blank')}
                                aria-label="reset"
                                hasArrow
                                placement="top-start"
                              >
                                <Box>
                                  <Icon
                                    as={BiReset}
                                    fontSize="20px"
                                    onClick={() =>
                                      logic.handlerClearSelectAndBackToDraftToken(
                                        item.id
                                      )
                                    }
                                  />
                                </Box>
                              </Tooltip>
                              <Box>
                                <PrintButton
                                  id={
                                    dataDraft?.forms?.[index]?.id || ''
                                  }
                                  patientId={
                                    String(attendance?.patient_id) || ''
                                  }
                                  templates={templates}
                                  isIconButton
                                  typePrint="form"
                                  isHaveAttendance
                                />
                              </Box>
                              <CloseButton
                                _hover={{ bg: 'red', color: 'white' }}
                                onClick={() => {
                                  setTypeExclude('token')
                                  setIndexExclude(index)
                                  openModalExclude()
                                }}
                              />
                            </Flex>
                            {item.isVisible && !item.form && (
                              <Draft
                                defaultValue={
                                  logic.contentsToken[index]?.content
                                }
                                getValue={(value: any) => {
                                  logic.getValueDraftTokenById(value, index)
                                }}
                              />
                            )}
                            {item.isVisible && item.form && (
                              <Flex w="100%" p="2" gap={2} flexDir="column">
                                <Divider />
                                {item.form.groups.map((group, indexGroup) => (
                                  <Flex
                                    flexDir="column"
                                    w="100%"
                                    key={group.id}
                                  >
                                    <Heading as="h1" size="sm" noOfLines={1}>
                                      {group.name}
                                    </Heading>
                                    {group.fields.map((field, indexField) => (
                                      <Element
                                        key={field.name || field.id}
                                        field={field}
                                        handlerChangeFormValues={
                                          handlerChangeFormValues
                                        }
                                      />
                                    ))}
                                  </Flex>
                                ))}
                              </Flex>
                            )}
                          </Flex>
                        </>
                      ))}
                  </VStack>
                  <Flex w="100%" justifyContent="flex-start">
                    <Button
                      size="sm"
                      bg="blue.300"
                      color="white"
                      onClick={() => logic.handlerAddNewToken()}
                    >
                      {t('container3.btnAdd')}
                    </Button>
                  </Flex>
                </CardContainer>
              </TabPanel>
              <TabPanel>
                <CardContainer title={t('container4.title')}>
                  {isLoadingSave && (
                    <Flex position="absolute" top="2" right="4">
                      <Text fontSize="12" fontWeight="hairline">
                        {t('autosave.message')}
                      </Text>
                    </Flex>
                  )}
                  <VStack spacing="4" w="100%" mb="2">
                    {logic.prescriptions.length > 0 &&
                      logic.prescriptions.map((pres, index: number) => (
                        <>
                          <Flex
                            flexDirection="column"
                            w="100%"
                            justifyContent="flex-start"
                            key={pres.id}
                          >
                            <Flex
                              w="100%"
                              alignItems="center"
                              justifyContent="flex-start"
                              gap={4}
                              mb="6px"
                            >
                              <SelectComponent
                                name="prescriptionSelected"
                                placeholder={t(
                                  'container4.placeholder.content'
                                )}
                                options={
                                  pres.isPreference === true
                                    ? services.prescriptionsSelectPreference
                                    : services.prescriptionsSelect
                                }
                                value={pres.selected}
                                onChange={(event: any) =>
                                  logic.handlerSelectPrescription(
                                    pres.id,
                                    index,
                                    event
                                  )
                                }
                              />
                              {pres.isChange && (
                                <Text fontWeight="hairline" fontSize="12px">
                                  {t('container4.isChange')}
                                </Text>
                              )}
                              <Tooltip
                                label={t('preference')}
                                aria-label="preference"
                                hasArrow
                                placement="top-start"
                              >
                                <Box>
                                  <Switch
                                    name="prescriptionsIsPreference"
                                    isChecked={pres.isPreference}
                                    onChange={(event) =>
                                      logic.handlerClickIsPreferencePrescription(
                                        pres.id,
                                        pres.isPreference
                                      )
                                    }
                                  />
                                </Box>
                              </Tooltip>
                              {pres.isVisible ? (
                                <Icon
                                  as={FiEye}
                                  _hover={{ color: 'red', cursor: 'pointer' }}
                                  fontSize="20px"
                                  onClick={() =>
                                    logic.handlerClickIsVisiblePrescription(
                                      pres.id,
                                      pres.isVisible
                                    )
                                  }
                                />
                              ) : (
                                <Icon
                                  as={FiEyeOff}
                                  _hover={{ color: 'green', cursor: 'pointer' }}
                                  fontSize="20px"
                                  onClick={() =>
                                    logic.handlerClickIsVisiblePrescription(
                                      pres.id,
                                      pres.isVisible
                                    )
                                  }
                                />
                              )}
                              <Box>
                                <PrintButton
                                  id={
                                    dataDraft?.prescriptions?.[index]?.id || ''
                                  }
                                  patientId={
                                    String(attendance?.patient_id) || ''
                                  }
                                  templates={templates}
                                  isIconButton
                                  typePrint="prescription"
                                  isHaveAttendance
                                />
                              </Box>
                              <CloseButton
                                _hover={{ bg: 'red', color: 'white' }}
                                onClick={() => {
                                  setTypeExclude('prescription')
                                  setIndexExclude(index)

                                  openModalExclude()
                                }}
                              />
                            </Flex>
                            {pres.isVisible && (
                              <Draft
                                defaultValue={
                                  logic.contentsPrescriptions[index]?.content
                                }
                                getValue={(value: any) => {
                                  logic.getValueDraftPrescriptionById(
                                    value,
                                    index
                                  )
                                }}
                                backContentRender={
                                  logic.contentsPrescriptions[index]
                                    ?.backContentRender
                                }
                              />
                            )}
                          </Flex>
                        </>
                      ))}
                  </VStack>
                  <Flex w="100%" justifyContent="flex-start">
                    <Button
                      size="sm"
                      bg="blue.300"
                      color="white"
                      onClick={() => {
                        logic.handlerAddNewPrescription()
                      }}
                    >
                      {t('container4.btnAdd')}
                    </Button>
                  </Flex>
                </CardContainer>
              </TabPanel>
              <TabPanel>
                <CardContainer title={t('container5.title')}>
                  {isLoadingSave && (
                    <Flex position="absolute" top="2" right="4">
                      <Text fontSize="12" fontWeight="hairline">
                        {t('autosave.message')}
                      </Text>
                    </Flex>
                  )}
                  <VStack spacing="4" w="100%" mb="2">
                    {logic.examsRequest.length > 0 &&
                      logic.examsRequest.map((exam, index: number) => (
                        <>
                          <Flex
                            flexDirection="column"
                            w="100%"
                            justifyContent="flex-start"
                            key={exam.id}
                          >
                            <Flex
                              w="100%"
                              alignItems="center"
                              justifyContent="flex-start"
                              gap={4}
                              mb="6px"
                            >
                              <SelectComponent
                                name="examSelected"
                                placeholder={t(
                                  'container5.placeholder.content'
                                )}
                                options={
                                  exam.isPreference === true
                                    ? services.examsSelectPreference
                                    : services.examsSelect
                                }
                                value={exam.selected}
                                onChange={(event: any) =>
                                  logic.handlerSelectExamRequest(
                                    exam.id,
                                    index,
                                    event
                                  )
                                }
                              />
                              {exam.isChange && (
                                <Text fontWeight="hairline" fontSize="12px">
                                  {t('container5.isChange')}
                                </Text>
                              )}
                              <Tooltip
                                label={t('preference')}
                                aria-label="preference"
                                hasArrow
                                placement="top-start"
                              >
                                <Box>
                                  <Switch
                                    name="prescriptionsIsPreference"
                                    isChecked={exam.isPreference}
                                    onChange={(event) =>
                                      logic.handlerClickIsPreferenceExamRequest(
                                        exam.id,
                                        exam.isPreference
                                      )
                                    }
                                  />
                                </Box>
                              </Tooltip>
                              {exam.isVisible ? (
                                <Icon
                                  as={FiEye}
                                  _hover={{ color: 'red', cursor: 'pointer' }}
                                  fontSize="20px"
                                  onClick={() => {
                                    logic.handlerClickIsVisibleExamRequest(
                                      exam.id,
                                      exam.isVisible
                                    )
                                  }}
                                />
                              ) : (
                                <Icon
                                  as={FiEyeOff}
                                  _hover={{ color: 'green', cursor: 'pointer' }}
                                  fontSize="20px"
                                  onClick={() => {
                                    logic.handlerClickIsVisibleExamRequest(
                                      exam.id,
                                      exam.isVisible
                                    )
                                  }}
                                />
                              )}
                              <Box>
                                <PrintButton
                                  id={dataDraft?.exams?.[index]?.id || ''}
                                  patientId={
                                    String(attendance?.patient_id) || ''
                                  }
                                  templates={templates}
                                  isIconButton
                                  typePrint="exam"
                                  isHaveAttendance
                                />
                              </Box>
                              <CloseButton
                                _hover={{ bg: 'red', color: 'white' }}
                                onClick={() => {
                                  setTypeExclude('exam')
                                  setIndexExclude(index)

                                  openModalExclude()
                                }}
                              />
                            </Flex>
                            {exam.isVisible && (
                              <Draft
                                defaultValue={
                                  logic.contentsExams[index]?.content
                                }
                                getValue={(value: any) => {
                                  logic.getValueDraftExamRequestById(
                                    value,
                                    index
                                  )
                                }}
                                backContentRender={
                                  logic.contentsExams[index]?.backContentRender
                                }
                              />
                            )}
                          </Flex>
                        </>
                      ))}
                  </VStack>
                  <Flex w="100%" justifyContent="flex-start">
                    <Button
                      size="sm"
                      bg="blue.300"
                      color="white"
                      onClick={() => {
                        logic.handlerAddNewExamRequest()
                      }}
                    >
                      {t('container5.btnAdd')}
                    </Button>
                  </Flex>
                </CardContainer>
              </TabPanel>
              <TabPanel>
                <CardContainer title={t('container6.title')}>
                  {isLoadingSave && (
                    <Flex position="absolute" top="2" right="4">
                      <Text fontSize="12" fontWeight="hairline">
                        {t('autosave.message')}
                      </Text>
                    </Flex>
                  )}
                  {dataDraft !== null && dataDraft?.indications?.length > 0 && (
                    <Box position="absolute" top="4" right="4">
                      <PrintButton
                        id={dataDraft?.attendance_id || ''}
                        patientId={String(attendance?.patient_id) || ''}
                        templates={templates}
                        typePrint="indication"
                        isHaveAttendance
                      />
                    </Box>
                  )}
                  <Stack
                    w="100%"
                    direction="column"
                    my="2"
                    spacing="6"
                    position="relative"
                  >
                    {logic.indications?.map((indication, index: number) => {
                      return (
                        <Flex
                          w="100%"
                          position="relative"
                          direction="row"
                          p="2"
                          key={indication.id}
                        >
                          <Box w="50%" mx="2">
                            <SelectComponent
                              name="serviceIndication"
                              placeholder={t('container6.placeholder.service')}
                              title={t('container6.service')}
                              options={services.allIndications}
                              value={indication.service}
                              onChange={(event: any) =>
                                logic.handlerSelectIndication(
                                  indication.id,
                                  event
                                )
                              }
                            />
                          </Box>
                          <Box w="20%" mx="2">
                            <Input
                              name="dateIndication"
                              label={t('container6.date')}
                              type="date"
                              isRequired
                              value={indication.indicationDate}
                              onChange={(event) =>
                                logic.handlerChangeIndicationsDate(
                                  indication.id,
                                  event
                                )
                              }
                            />
                          </Box>
                          <Box w="30%" mx="2">
                            <Input
                              name="observationIndication"
                              label={t('container6.observation')}
                              value={indication.observation}
                              onChange={(event) =>
                                logic.handlerChangeObservationOfIndication(
                                  indication.id,
                                  event
                                )
                              }
                              placeholder={t(
                                'container6.placeholder.observation'
                              )}
                            />
                          </Box>
                          <Box>
                            <Button
                              colorScheme="red"
                              size="xs"
                              onClick={() =>
                                logic.handlerRemoveIndications(index)
                              }
                            >
                              x
                            </Button>
                          </Box>
                        </Flex>
                      )
                    })}
                    <Flex w="100%">
                      <Button
                        bg="blue.300"
                        color="white"
                        size="sm"
                        onClick={() => logic.handlerAddNewIndications()}
                      >
                        Adicionar indicação
                      </Button>
                    </Flex>
                  </Stack>
                </CardContainer>
              </TabPanel>
            </TabPanels>
          </Tabs>

          <Flex w="100%" alignItems="center" justifyContent="flex-end">
            <Button
              colorScheme="red"
              size="md"
              onClick={() => openModalFinished()}
            >
              Finalizar atendimento
            </Button>
          </Flex>
          <Flex w="100%" h="300px" />
        </Stack>
      </LayoutDefault>
      <ModalContentComponent
        isOpen={isOpen}
        onClose={() => closeModalHistory()}
        title="Histórico"
      >
        Em desenvolvimento
      </ModalContentComponent>
      <ModalArrivedTime
        isOpen={isFinished}
        onClose={() => {
          closeModalFinished()
        }}
        onUpdate={() => {
          handleFinalizeAttendance()
        }}
        title={t('modalFinishedTitle')}
      >
        <VStack w="100%" spacing="4">
          <List w="100%" spacing="4">
            {servicesPerformed.map((item) => (
              <ListItem key={item.serviceId}>
                <Flex
                  gap={4}
                  alignItems="flex-start"
                  w="100%"
                  justifyContent="flex-start"
                  flexDir="row"
                  my="2"
                >
                  <Checkbox
                    isChecked={item.fulfilled}
                    onChange={(event) => {
                      handlerChangeFulfilled(item.serviceId, event)
                    }}
                  />
                  <Text>{`${item.serviceName} - 1/${item?.total_session}`}</Text>
                </Flex>
                {!item.fulfilled && (
                  <Input
                    name="descriptionFulfilledService"
                    isRequired
                    value={item.description}
                    onChange={(event) => {
                      handlerChangeDescription(item.serviceId, event)
                    }}
                    label={t('descriptionLabel')}
                  />
                )}
              </ListItem>
            ))}
            {sessionsPerformed.map((item) => (
              <ListItem key={item.sessionId}>
                <Flex
                  gap={4}
                  alignItems="flex-start"
                  w="100%"
                  justifyContent="flex-start"
                  flexDir="row"
                  my="2"
                >
                  <Checkbox
                    isChecked={item.fulfilled}
                    onChange={(event) => {
                      handlerChangeFulfilledSessions(item.sessionId, event)
                    }}
                  />
                  <Text>{item.serviceName}</Text>
                </Flex>
                {!item.fulfilled && (
                  <Input
                    name="descriptionFulfilledService"
                    isRequired
                    value={item.description}
                    onChange={(event) => {
                      handlerChangeDescriptionSessions(item.sessionId, event)
                    }}
                    label={t('descriptionLabel')}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </VStack>
      </ModalArrivedTime>
      <ModalConfirmExclude
        isOpen={isExcluded}
        onClose={() => {
          closeModalExclude()
        }}
        title={t('modalExclude.title')}
        onConfirmExclude={() => {
          switch (typeExclude) {
            case 'token':
              logic.handlerRemoveToken(indexExclude)
              break
            case 'exam':
              logic.handlerRemoveExamRequest(indexExclude)
              break
            case 'prescription':
              logic.handlerRemovePrescription(indexExclude)
              break
            default:
              null
          }

          closeModalExclude()
        }}
      >
        {t('modalExclude.message')}
      </ModalConfirmExclude>
    </>
  )
}
