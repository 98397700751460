/* eslint-disable no-else-return */
import axios, { AxiosError } from 'axios'
import ls from 'localstorage-slim'
import { queryClient } from './reactQuery'
import { SignOutAuth, expireDateNowAdd8hours } from '../hooks/auth'

const apiAuth = axios.create({
  baseURL: 'https://api-prod.clinik.net/api/v1/',
  headers: {
    Authorization: `Bearer ${ls.get('@Clinik:token')}`
  }
})

let isRefreshing = false
let failedRequestsQueue: any = []

apiAuth.interceptors.request.use((request) => {
  const token = ls.get('@Clinik:token')

  if (request.method === 'POST') {
    queryClient.invalidateQueries()
  }
  if (request.method === 'PUT') {
    queryClient.invalidateQueries()
  }

  request.headers = {
    Authorization: `Bearer ${token}`
  }

  return request
})

apiAuth.interceptors.response.use(
  (response) => {
    return response
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (error.response.data?.code === 'token_not_valid') {
        const refresh = ls.get('@Clinik:refreshToken')
        const originalConfig = error.config

        if (!isRefreshing) {
          isRefreshing = true

          apiAuth
            .post('token/refresh/', {
              refresh
            })
            .then((response) => {
              const { data } = response
              const ttl = expireDateNowAdd8hours()
              ls.set('@Clinik:token', data.access, { ttl })
              ls.set('@Clinik:refreshToken', data.refresh, { ttl })

              apiAuth.defaults.headers = {
                Authorization: `Bearer ${data.access}`
              }

              failedRequestsQueue.forEach((request: any) => {
                request.onSuccess(data.access)
              })

              failedRequestsQueue = []
            })
            .catch((err: AxiosError) => {
              failedRequestsQueue.forEach((request: any) => {
                request.onFailure(err)
              })

              failedRequestsQueue = []
            })
            .finally(() => {
              isRefreshing = false
            })
        }

        return new Promise<any>((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: (token: string) => {
              originalConfig.headers = {
                Authorization: `Bearer ${token}`
              }

              resolve(apiAuth(originalConfig))
            },
            onFailure: (e: AxiosError) => {
              reject(e)
            }
          })
        })
      } else {
        SignOutAuth()
      }
    }

    if (error.response?.status === 403) {
      SignOutAuth()
    }

    return Promise.reject(error)
  }
)

export { apiAuth }
